import * as React from "react"

const NotFoundPage = () => (
  <section className="py-16 wrapper text-center">
    <h1>404: Not Found</h1>
    <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
  </section>
)

export default NotFoundPage
